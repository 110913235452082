/* eslint react/display-name: 0 */

const columns = ({}, hideCols) => [
	{
		Header: 'First Name',
		id: 'firstname',
		filterable: true,
		minWidth: 150,
		accessor: 'firstname',
		foldable: true,
	},
	{
		Header: 'Last Name',
		id: 'lastname',
		filterable: true,
		minWidth: 150,
		accessor: 'lastname',
		foldable: true,
	}
];

export default columns;