import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';
import Widget04 from './Widget04';
import Loading from '../Loading/';
import axios from 'axios';
import CommissionWidget from './CommissionWidget';
import RepRankWidget from './RepRankWidget';
import WelcomeWidget from './WelcomeWidget';
import { getCurrentMonthRange } from '../../utils/date';
import QUERY_DASHBOARD_USER from '../../queries/dashboard';
import styles from './dashboard.module.scss';
import TestComponent from './TestComponent';

const Dashboard = ({ history }) => {
	var date = new Date();

	const { data, loading } = useQuery(QUERY_DASHBOARD_USER, {
		variables: {
			month: date.getMonth(),
			year:	date.getFullYear(),
		}
	});
console.log(data,loading)
	if(loading || !data) { return <Loading />; }

	const { user } = data;

	const { firstname, lastname, SalesInfo, accountType, representativeLevel, representativeID } = user;

	const { allSalesTotal, childCount, Commission, Month } = SalesInfo;
	const { need } = Commission;
	const { sales } = Month;

	let goal = (need - allSalesTotal);
	goal = goal < 0 ? 0 : goal;
	const progressTotalSales = (sales/goal*100);

	return (
		<div className="animated fadetwoIn padded-top dnp-page">
			<Grid stackable>
				<Grid.Column width={4}>
					<Widget04 
						icon="icon-basket-loaded"
						color="primary"
						header={`$${sales.toFixed(2)}`}
						value={`${progressTotalSales}`}
						rightText={`Goal: $${goal.toFixed(2)}`}
						invert
					>
						Sold This Month
					</Widget04>
					<RepRankWidget 
						color="danger"
						variant="inverse"
						childcount={childCount}
						info={representativeLevel}
					/>
				</Grid.Column>

				<Grid.Column width={8}>
					<WelcomeWidget 
						message="Welcome to the Brand New Backoffice!"
						name={`${firstname} ${lastname}`}
					/>
				</Grid.Column>

				<Grid.Column width={4}>
					<CommissionWidget
						commission={Commission}
						accountType={accountType}
					/>
					{representativeID && (<div className={styles.educatorID}>
						<h4>Your Educator ID</h4>
						<h3>{representativeID}</h3>
					</div>)}
				</Grid.Column>
			</Grid>
			{/* <TestComponent /> */}
		</div>
	);
};

function mapStateToProps({userInfo}) {
	return ({ userInfo });
}

export default connect(mapStateToProps)(Dashboard);
