import React from 'react';
import _ from 'lodash';
import { Segment, Button } from 'semantic-ui-react';
import FreeMembersTable from './FreeMembersTable';
import { useQuery } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../../queries/user';
import Loading from '../Loading/index';

const FreeMembers = () => {
	const childRef = React.useRef(null);
	const { data, loading } = useQuery(QUERY_CURRENT_USER);
	return (
		<div className="animated fadetwoIn dnp-page">
			<Segment.Group stacked={true}>
				<Segment color='green' className="tableHeaderContainer">
					<div></div>
					<h2>Free Members</h2>
					<Button basic onClick={() => childRef.current.refresh()}>
						<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true"></i>
					</Button>
				</Segment>
				<Segment placeholder>
					{loading ? <Loading /> : <FreeMembersTable ref={childRef} parent={_.get(data, 'user.id')} /> }
				</Segment>
			</Segment.Group>
		</div>
	);
};

export default FreeMembers;