import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useRouteMatch, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';

import Page401 from '../Page401/';
import Page404 from '../Page404/';
import Sidebar from '../Sidebar';
import Loading from '../Loading/';
import Dashboard from '../Dashboard/';
import Sales from './NewSales';
import UserSettings from '../UserSettings/UserSettings';
import Support from '../Support/';
import AdminMain from '../Admin/Admin';
import Payment from '../Payment/';
import DeveloperTools from '../Developer/Tools/DeveloperTools';
import Documents from '../Documents';
import CheckMembershipPage from '../CheckMembership';
import Training from '../Training';
import SocialMedia from '../SocialMedia';
import SellMemberships from '../SellMemberships';
import EmployeeArea from '../EmployeeArea';
import ReferralPage from '../Referral';
import { useQuery } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../../queries/user';

import Header from '../Header/_Header';
import UserTreeGraph from '../UserTreeGraph';
// import BusinessAffiliates from '../BusinessAffiliates';
import FreeMembers from '../FreeMembers';
import PayingMembers from '../PayingMembers';
import Orders from './Orders';
import BusinessPlan from '../BusinessPlan';

function AdminRoute ({component: Component, accountType, ...rest}) {
	return (
		<Route
			{...rest}
			render={(props) => (accountType >= 0) === true ? <Component {...props} /> : <Page401 />}
		/>
	);
}

function DeveloperRoute ({component: Component, accountType, ...rest}) {
	return (
		<Route
			{...rest}
			render={(props) => (accountType >= 42) === true ? <Component {...props} /> : <Page401 />}
		/>
	);
}

function SpecialRoute ({component: Component, accountType, allowed = [42], ...rest}) {
	return (
		<Route
			{...rest}
			render={(props) => allowed.includes(accountType) ? <Component {...props} /> : <Page401 />}
		/>
	);
}

function BusinessPlanRoute({ component: Component, user, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => (user && user.hasBusinessPlan) ? <Component user={user} {...props} /> : <Page401 />}
		/>
	);
}

function EmployeeRoute({ component: Component, employee, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => employee ? <Component {...props} /> : <Page401 />}
		/>
	);
}

function FowardPropsRoute({ component: Component, user, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => <Component {...props} user={user} />}
		/>
	);
}


function RedirectRoute({ to, ...rest }) {
	return (
		<Route
			{...rest}
			render={() => <Redirect to={to} />}
		/>
	);
}

const Office = () => {
	const [menuOpen, setMenuOpen] = useState(window.innerWidth > 750);
	const { loading, data } = useQuery(QUERY_CURRENT_USER);
	const user = _.get(data, 'user', {}) || {};
	const history = useHistory();

	useEffect(() => {
		if(history.location.pathname.startsWith('/office')) {
			history.push(`${history.location.pathname.replace('office/', '')}${history.location.search}`);
		}
	}, [history.location.pathname]);

	useEffect(() => {
		let mobile = false;
		const resizeEvent = () => {
			if(window.innerWidth > 750) {
				if(mobile) {
					setMenuOpen(true);
					mobile = false;
				}
			} else if (window.innerWidth < 750) {
				if(!mobile) {
					setMenuOpen(false);
					mobile = true;
				}
			}
		};
		window.addEventListener('resize', resizeEvent);
		return () => window.removeEventListener('resize', resizeEvent);
	}, []);
	
	if(loading || !user.id) { return <Loading />; }

	let redirectTo = '/dashboard';

	if(user.accountType === 0 && user.hasBusinessPlan) {
		redirectTo = '/business';
	}

	return(
		<div className="app" id="myapp">
			<Sidebar user={user} open={menuOpen} closeSideBar={() => setMenuOpen(false)} />
			<main className="main" id="main">
				<Header onHamburgerClick={() => setMenuOpen(!menuOpen)} user={user} />
				<Switch>
					{/*Load routes here for the Backoffice*/}
					<RedirectRoute exact path='/' to={redirectTo} />
					<Route exact path='/dashboard' component={Dashboard} />
					<Route exact path='/sales' component={Sales} />
					<Route exact path='/orders' component={Orders} />
					<Route path='/additions' component={SocialMedia} />
					<Route path='/orders/:id' component={Orders} />
					<Route exact path='/team/treegraph' component={UserTreeGraph} />
					{/* <Route exact path='/team/businessaffiliates' component={BusinessAffiliates} /> */}
					<Route exact path='/team/freemembers' component={FreeMembers} />
					<Route exact path='/team/payingmembers' component={PayingMembers} />
					<Route exact path='/settings' component={UserSettings} />
					<Route exact path='/support' component={Support} />

					{/*Tools*/}
					<Route path='/tools/sell' component={SellMemberships} />
					{/* <Route path='/tools/resources/:slug/:resource' component={Documents} /> */}
					{/* <Route path='/tools/resources/:slug' component={Documents} /> */}
					<Route path='/tools/resources/:route+' component={Documents} />
					<Route exact path='/tools/resources' component={Documents} />
					<Route exact path='/tools/training' component={Training} />

					<Route exact path='/tools/payment' component={Payment} />
					<Route exact path="/tools/referral" component={ReferralPage} />
					<SpecialRoute accountType={user.accountType} allowed={[2,3,10,42]} path='/tools/validate' component={CheckMembershipPage} />


					{/*Admin Protected Routes**/}
					<FowardPropsRoute
						path='/business'
						component={BusinessPlan}
						user={user}
					/>
					<AdminRoute accountType={user.accountType} path='/admin' component={AdminMain} />
					<DeveloperRoute accountType={user.accountType} path='/developer' component={DeveloperTools} />
					{/* <EmployeeRoute employee={user.employee} path='/employee' component={EmployeeArea} /> */}
					<Route component={Page404} />
				</Switch>
			</main>
		</div>
	);
};

export default Office;
