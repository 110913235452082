import gql from 'graphql-tag';

const queryGetPayingMembers = gql`

	query GetPayingMembers($id: String!,$skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getPayingMembers(id: $id,skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			id
			firstname
			lastname
			email
			currentMonthSales
			parent {
				id
				firstname
				lastname
				email
				parent {
					id
					firstname
					lastname
					email
				}
			}
			downline {
				id
				firstname
				lastname
				email
				currentMonthSales
			}
		}
    }
`;

export default queryGetPayingMembers;