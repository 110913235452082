const links = [
	// Normal Links for all Users
	{
		name: 'Navigation',
		accountLevelMin: 1,
		restricted: false,
		items: [{
			name: 'Dashboard',
			url: '/dashboard',
			icon: ['far', 'home-alt']
		}, {
			// 	name: 'Sales',
			// 	url: '/sales',
			// 	icon: ['far', 'money-check-alt']
			// }, {
			name: 'Orders',
			url: '/orders',
			icon: ['far', 'money-check-alt']
		}, {
			name: 'Business Additions',
			url: '/additions',
			icon: ['fas', 'hashtag']
		}, {
			name: 'Tools',
			url: '/tools',
			icon: ['far', 'tools'],
			children: [{
				name: 'Sell Memberships',
				url: '/tools/sell',
				icon: ['fas', 'id-card'],
			}, {
				name: 'Send Referral',
				url: '/tools/referral',
				icon: ['fas', 'paper-plane'],
			}, {
				name: 'Resources',
				url: '/tools/resources',
				icon: ['fas', 'file-contract'],
			}, {
				name: 'View Training',
				url: '/tools/training',
				icon: ['fas', 'graduation-cap'],
			}, {
				name: 'Payment',
				url: '/tools/payment',
				icon: ['fas', 'piggy-bank']
			}, {
				name: 'Check Memberships',
				url: '/tools/validate',
				icon: ['fas', 'search'],
			}],
		}, {
			name: 'Referrals',
			url: '/team',
			icon: ['far', 'users-crown'],
			disableForFriends: true,
			children: [{
				name: 'Paying Members',
				url: '/team/payingmembers',
				icon: ['fas', 'chart-network'],
			}, {
				name: 'Free Members',
				url: '/team/freemembers',
				icon: ['far', 'store'],
			}],
		}, {
			name: 'Support',
			url: '/support',
			icon: ['far', 'question'],
		}], // End of Navigation Links
	},
	// Business Plan Links
	{
		name: 'Deluxe Members',
		accountLevelMin: 0,
		restricted: true,
		hasBusinessPlan: true,
		items: [{
			name: 'Home',
			url: '/business',
			icon: ['far', 'home-alt'],
		}, {
			name: 'Profile',
			url: '/business/store',
			icon: ['far', 'edit'],
		}, {
			name: 'Add Family/Employees',
			url: '/business/memberships',
			icon: ['far', 'credit-card-blank'],
		}, {
			name: 'Resources',
			url: '/business/resources',
			icon: ['fas', 'file-contract'],
			skipRequirement: true
		}],
	},
	// Admin Links
	{
		name: 'Admin',
		accountLevelMin: 10,
		restricted: true,
		items: [{
			name: 'Users',
			url: '/admin/users',
			icon: ['far', 'users'],
			minLevel: 1,
			children: [{
				name: 'Manage Users',
				url: '/admin/users',
				icon: ['fas', 'users-cog'],
				minLevel: 1,
			}, {
				name: 'View Tree Graphs',
				url: '/admin/users/treegraph',
				icon: ['fas', 'sitemap'],
				minLevel: 1,
				// }, {
				// 	name: 'Managers',
				// 	url: '/admin/users/managers',
				// 	icon: ['fas', 'user-tie'],
				// 	minLevel: 3,
			}],
		}, {
			name: 'Orders',
			url: '/admin/sales',
			icon: ['far', 'sack-dollar'],
			minLevel: 1,
			children: [{
				// 	name: 'View Sales',
				// 	url: '/admin/sales',
				// 	icon: ['fas', 'file-invoice-dollar'],
				// 	minLevel: 1,
				// }, {
				name: 'Manage Orders',
				url: '/admin/orders',
				icon: ['fas', 'file-invoice-dollar'],
				minLevel: 1,
			}, {
				name: 'Add Order',
				url: '/admin/sales/add',
				icon: ['fas', 'plus'],
				minLevel: 2,
			}, {
				name: 'Add Corporate Plan',
				url: '/admin/sales/addcorporateplan',
				icon: ['fas', 'briefcase'],
				minLevel: 2,
			}, {
				name: 'Sell Corporate Plan',
				url: '/admin/sales/sellcorporateplan',
				icon: ['fas', 'envelope-open-dollar'],
				minLevel: 2,
			}, {
				name: 'Promo Codes',
				url: '/admin/sales/promocodes',
				icon: ['fas', 'money-bill-wave-alt'],
				minLevel: 3,
			}, {
				name: 'Renewal Report',
				url: '/admin/sales/renewals',
				icon: ['fas', 'file-chart-pie'],
				minLevel: 2,
			}],
		}, {
			name: 'Memberships',
			url: '/admin/memberships',
			icon: ['far', 'credit-card-blank'],
			minLevel: 1,
		}, {
			name: 'Business Plans',
			url: '/admin/businessplans',
			icon: ['far', 'credit-card-blank'],
			minLevel: 1,
		}, {
			name: 'Business Affiliates',
			url: '/admin/stores',
			icon: ['far', 'store'],
			minLevel: 1,
		}, {
			name: 'Referrals',
			url: '/admin/referrals',
			icon: ['far', 'mailbox'],
			minLevel: 1,
		}, {
			name: 'Seminar',
			url: '/admin/seminar',
			icon: ['far', 'webcam'],
			minLevel: 1,
		}, {
			name: 'Tools',
			url: '/admin/tools',
			icon: ['far', 'tools'],
			minLevel: 2,
			children: [{
				name: 'Commission Reports',
				url: '/admin/tools/commissionreport',
				icon: ['fas', 'usd-square'],
				minLevel: 2,
			}, {
				// 	name: 'Connected Users',
				// 	url: '/admin/tools/users',
				// 	icon: ['fas', 'users-class'],
				// 	minLevel: 3,
				// }, {
				name: 'Webinar',
				url: '/admin/tools/webinar',
				icon: ['fas', 'video'],
				minLevel: 3,
			}, {
				name: 'Manage Training',
				url: '/admin/tools/training',
				icon: ['fas', 'graduation-cap'],
				minLevel: 3,
			}],
		}] // End of Admin Links
	},

	// Developer Links
	{
		name: 'Developer',
		accountLevelMin: 42,
		restricted: true,
		items: [{
			name: 'Test Area',
			url: '/developer/testarea',
			icon: ['fas', 'vial'],
			minLevel: 3,
			children: [{
				name: 'Current Tests',
				url: '/developer/testarea/current',
				icon: ['fas', 'treasure-chest'],
				minLevel: 3,
			}],
		}, {
			name: 'Resources',
			url: '/developer/resources',
			icon: ['fas', 'tire-pressure-warning'],
			minLevel: 3,
			children: [{
				name: 'Tools',
				url: '/developer/resources/tools',
				icon: ['fas', 'hammer'],
				minLevel: 3,
			}],
		}] // End of Developer Links
	},

];

export default links;
